import {useCallback, useEffect, useRef, useState} from 'react';
import {
    formatTime,
    getBrowserArtWorks,
    scrollToBottom,
    scrollToTop,
} from '../../utils';
import Slider from 'react-input-slider';

export const Controls = ({
     options,
     toolBar,
     currentTrack,
     duration,
     isPlaying,
     setIsPlaying,
     setCurrentTrack,
     setDuration,
     setTrackIndex,
     trackIndex,
     tracks,
     playlistData,
 }) => {
    // const [volume, setVolume] = useState(60);
    // const [muteVolume, setMuteVolume] = useState(false);
    const [timeProgress, setTimeProgress] = useState(0);

    // reference
    const audioRef = useRef();
    const progressBarRef = useRef();
    const playAnimationRef = useRef();

    const togglePlayPause = () => {
        if (!trackIndex) {
            setTrackIndex(0);
            setCurrentTrack(tracks[0]);
        }

        setIsPlaying((prev) => !prev);
    };

    const skipForward = () => {
        audioRef.current.currentTime += 15;
    };

    const skipBackward = () => {
        audioRef.current.currentTime -= 15;
    };

    const handleNextPrevious = (isNext = true) => {
        setTrackIndex((prev) => {
            let newIndex = isNext ? prev + 1 : prev - 1;
            if (newIndex < 0 || newIndex >= tracks.length) {
                newIndex = 0;
            }
            setCurrentTrack(tracks[newIndex]);
            return newIndex;
        });
    };

    useEffect(() => {
        if (currentTrack) {
            audioRef.current.setAttribute('title', currentTrack.name);
        }
    }, [currentTrack]);

    const repeat = useCallback(() => {
        if (!audioRef?.current) {
            return;
        }

        const currentTime = audioRef.current.currentTime;
        //console.log(currentTime)
        setTimeProgress(currentTime);

        if (progressBarRef.current) {
            progressBarRef.current.value = currentTime;
            progressBarRef.current.style.setProperty(
                '--range-progress',
                `${(progressBarRef.current?.value / duration) * 100}%`
            );
        }

        // Todo check if needed, causing infinite component rendering
        playAnimationRef.current = requestAnimationFrame(repeat);
    }, [audioRef, duration, progressBarRef, setTimeProgress]);

    useEffect(() => {
        if (isPlaying) {
            audioRef.current.play();
        } else {
            audioRef.current.pause();
        }
        playAnimationRef.current = requestAnimationFrame(repeat);
    }, [isPlaying, audioRef, repeat]);

    // Disabling Volume

    // useEffect(() => {
    //     if (audioRef) {
    //         audioRef.current.volume = volume / 100;
    //         audioRef.current.muted = muteVolume;
    //     }
    // }, [volume, audioRef, muteVolume]);

    if (!audioRef) {
        return null;
    }

    const onLoadedMetadata = async () => {
        const seconds = audioRef.current.duration;
        console.log('seconds', audioRef.current.duration)
        setDuration(seconds);

        if (navigator.mediaSession) {
            if (currentTrack) {
                const artworks = await getBrowserArtWorks(currentTrack.img);

                navigator.mediaSession.metadata = new MediaMetadata({
                    title: currentTrack.name,
                    artist: '',
                    album: playlistData.name,
                    artwork: artworks,
                });
            }

            navigator.mediaSession.setActionHandler('play', () =>
                togglePlayPause()
            );
            navigator.mediaSession.setActionHandler('pause', () =>
                togglePlayPause()
            );
            navigator.mediaSession.setActionHandler('seekbackward', () =>
                skipBackward()
            );
            navigator.mediaSession.setActionHandler('seekforward', () =>
                skipForward()
            );
            navigator.mediaSession.setActionHandler('previoustrack', () =>
                handleNextPrevious(false)
            );
            navigator.mediaSession.setActionHandler('nexttrack', () => {
                handleNextPrevious(true);
            });
        }
    };

    return (
        <>
            <div className="display">
                <div className="display__row">{options()}</div>
                <div className="display__row">
                    <div className="track-info">
                        <div
                            className="audio-image"
                            onClick={() => scrollToTop()}
                            style={{
                                visibility: currentTrack?.img
                                    ? 'visible'
                                    : 'hidden',
                                backgroundImage: currentTrack?.img
                                    ? `url(${currentTrack?.img})`
                                    : 'none',
                                backgroundColor: 'black',
                            }}
                        />
                        <div className="container">
                            <div className="controls-box">
                                <i
                                    className="previous-track-btn disabled"
                                    onClick={() => handleNextPrevious(false)}
                                ></i>
                                <div
                                    className="large-toggle-btn"
                                    onClick={togglePlayPause}
                                >
                                    {isPlaying ? (
                                        <i className="large-pause-btn"></i>
                                    ) : (
                                        <i className="large-play-btn"></i>
                                    )}
                                </div>
                                <i
                                    className="next-track-btn"
                                    onClick={() => handleNextPrevious()}
                                ></i>
                            </div>
                        </div>
                        <div className="track-time">
                            <SmallProgressText
                                {...{
                                    progressBarRef,
                                    timeProgress,
                                    duration,
                                }}
                            />
                        </div>
                    </div>
                </div>
                {currentTrack && (
                    <div className="display__row">
                        <SmallProgress
                            {...{
                                progressBarRef,
                                audioRef,
                                timeProgress,
                                duration,
                            }}
                        />
                    </div>
                )}
                {toolBar && <div className="display__row">{toolBar?.()}</div>}
            </div>

            <audio
                src={currentTrack?.src}
                ref={audioRef}
                onLoadedMetadata={onLoadedMetadata}
                onEnded={() => handleNextPrevious(true)}
            />
        </>
    );
};

const SmallProgress = ({audioRef, timeProgress, duration}) => {
    const [position, setPosition] = useState(0);

    useEffect(() => {
        const currentPosition = parseInt((timeProgress * 100) / duration);
        setPosition(currentPosition);
    }, [timeProgress, duration]);

    const updatePosition = (pos) => {
        const seconds = parseInt((duration * pos) / 100);

        audioRef.current.currentTime = seconds;
    };

    return (
        <div className="small-progress">
            <Slider
                axis="x"
                x={position}
                onChange={(coords) => {
                    setPosition(coords.x);
                    updatePosition(coords.x);
                }}
            />
        </div>
    );
};

const SmallProgressText = ({timeProgress, duration}) => {
    const remainingTime = duration - timeProgress;

    if (isNaN(remainingTime)) {
        return null;
    }

    return (
        <div
            onClick={() => {
                scrollToBottom();
            }}
        >
            <div>{formatTime(remainingTime)}</div>
        </div>
    );
};
